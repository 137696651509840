// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-pages-all-news-all-news-js": () => import("./../../../src/components/pages/allNews/AllNews.js" /* webpackChunkName: "component---src-components-pages-all-news-all-news-js" */),
  "component---src-components-pages-news-page-single-news-page-js": () => import("./../../../src/components/pages/newsPage/SingleNewsPage.js" /* webpackChunkName: "component---src-components-pages-news-page-single-news-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspirations-about-js": () => import("./../../../src/pages/inspirations/about.js" /* webpackChunkName: "component---src-pages-inspirations-about-js" */),
  "component---src-pages-inspirations-cars-js": () => import("./../../../src/pages/inspirations/cars.js" /* webpackChunkName: "component---src-pages-inspirations-cars-js" */),
  "component---src-pages-inspirations-dating-sites-js": () => import("./../../../src/pages/inspirations/dating_sites.js" /* webpackChunkName: "component---src-pages-inspirations-dating-sites-js" */),
  "component---src-pages-inspirations-ecommerce-js": () => import("./../../../src/pages/inspirations/ecommerce.js" /* webpackChunkName: "component---src-pages-inspirations-ecommerce-js" */),
  "component---src-pages-inspirations-js": () => import("./../../../src/pages/inspirations.js" /* webpackChunkName: "component---src-pages-inspirations-js" */),
  "component---src-pages-inspirations-premium-brands-js": () => import("./../../../src/pages/inspirations/premium_brands.js" /* webpackChunkName: "component---src-pages-inspirations-premium-brands-js" */),
  "component---src-pages-inspirations-real-estate-js": () => import("./../../../src/pages/inspirations/real_estate.js" /* webpackChunkName: "component---src-pages-inspirations-real-estate-js" */),
  "component---src-pages-inspirations-restoration-js": () => import("./../../../src/pages/inspirations/restoration.js" /* webpackChunkName: "component---src-pages-inspirations-restoration-js" */),
  "component---src-pages-inspirations-sharing-js": () => import("./../../../src/pages/inspirations/sharing.js" /* webpackChunkName: "component---src-pages-inspirations-sharing-js" */),
  "component---src-pages-inspirations-web-publishing-js": () => import("./../../../src/pages/inspirations/web_publishing.js" /* webpackChunkName: "component---src-pages-inspirations-web-publishing-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-legal-documents-js": () => import("./../../../src/pages/legal_documents.js" /* webpackChunkName: "component---src-pages-legal-documents-js" */),
  "component---src-pages-optiwai-js": () => import("./../../../src/pages/optiwai.js" /* webpackChunkName: "component---src-pages-optiwai-js" */),
  "component---src-pages-optiwaiplus-about-js": () => import("./../../../src/pages/optiwaiplus/about.js" /* webpackChunkName: "component---src-pages-optiwaiplus-about-js" */),
  "component---src-pages-optiwaiplus-campaign-js": () => import("./../../../src/pages/optiwaiplus/campaign.js" /* webpackChunkName: "component---src-pages-optiwaiplus-campaign-js" */),
  "component---src-pages-optiwaiplus-explore-js": () => import("./../../../src/pages/optiwaiplus/explore.js" /* webpackChunkName: "component---src-pages-optiwaiplus-explore-js" */),
  "component---src-pages-optiwaiplus-integrations-js": () => import("./../../../src/pages/optiwaiplus/integrations.js" /* webpackChunkName: "component---src-pages-optiwaiplus-integrations-js" */),
  "component---src-pages-optiwaiplus-js": () => import("./../../../src/pages/optiwaiplus.js" /* webpackChunkName: "component---src-pages-optiwaiplus-js" */),
  "component---src-pages-optiwaiplus-pricing-js": () => import("./../../../src/pages/optiwaiplus/pricing.js" /* webpackChunkName: "component---src-pages-optiwaiplus-pricing-js" */),
  "component---src-pages-optiwaiplus-user-guide-js": () => import("./../../../src/pages/optiwaiplus/user_guide.js" /* webpackChunkName: "component---src-pages-optiwaiplus-user-guide-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-realestate-js": () => import("./../../../src/pages/realestate.js" /* webpackChunkName: "component---src-pages-realestate-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-use-cases-js": () => import("./../../../src/pages/use_cases.js" /* webpackChunkName: "component---src-pages-use-cases-js" */),
  "component---src-pages-user-guide-js": () => import("./../../../src/pages/user_guide.js" /* webpackChunkName: "component---src-pages-user-guide-js" */)
}

